import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const axios = require("axios")
const jQuery = require("jquery")

const Contacto = () => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [subject, setSubject] = useState("")
  const [phone, setPhone] = useState("")

  useEffect(() => {
    window.slider()
  }, [])

  const send = async function(e) {
      e.preventDefault()
      if (!executeRecaptcha) {
        return
      }
      if (jQuery("#firstname").val()) {
        return
      }
      if (email.length === 0 || email.trim().length === 0) {
        return
      } else if (message.length === 0 || message.trim().length === 0) {
        return
      } else if (subject.length === 0 || subject.trim().length === 0) {
        return
      } else if (name.length === 0 || name.trim().length === 0) {
        return
      }
      const result = await executeRecaptcha("contacto")
      const headers = {
        "Content-Type": "application/json"
      }
      axios
        .post(
          "https://us-central1-cloudsites-190318.cloudfunctions.net/mails",
          {
            name,
            email,
            message,
            subject,
            phone,
            token:result
          },
          {
            headers: headers
          }
        )
        .then(res => {
          jQuery("#contactform").slideUp("slow").hide()
          jQuery("#contactWrapper").append(
            "<div class=\"success\"><h4>Correo electrónico enviado correctamente!</h4><br><p>¡Gracias por usar nuestro formulario de contacto! <br> Su correo electrónico se envió correctamente pronto nos pondremos en contacto con usted.</p></div>"
          )
        })
    }

return (
  <Layout title="Contacto" show_bread={true}>
    <SEO title="Contacto"/>
    <div
      className={"section mcb-section equal-height"}
      style={{
        paddingTop: "100px",
        paddingBottom: "60px",
        backgroundColor: "#eaedef"
      }}
    >
      <div className={"section_wrapper mcb-section-inner"}>
        <div className={"wrap mcb-wrap one  valign-top clearfix"}>
          <div className={"mcb-wrap-inner"}>
            <div
              className={"column mcb-column two-third column_column"}
              style={{
                height: "556px",
                backgroundImage: "url(/images/home_hr_sectionbg4.png",
                backgroundRepeat: "repeat",
                backgroundPosition: "center"
              }}
            >
              <div
                className={"column_attr"}
                style={{ padding: "40px 8% 15px" }}
              >
                <h3 style={{ color: "#313444" }}>Contáctanos</h3>
                <hr className={"no_line"} style={{ margin: "0 auto 20px" }}/>
                <div id="contactWrapper">
                  <form
                    autoComplete={"off"}
                    id={"contactform"}
                    onSubmit={e => send(e)}
                  >
                    <input

                      name="firstname"
                      type="text"
                      id="firstname"
                      className={"hide-r"}
                    />

                    <div className={"column one-second"}>
                      <input
                        onChange={e => setName(e.target.value)}
                        required
                        placeholder="Nombre"
                        type="text"
                        name="name"
                        id="name"
                        aria-required="true"
                        aria-invalid="false"
                      />
                    </div>
                    <div className={"column one-second"}>
                      <input
                        onChange={e => setEmail(e.target.value)}
                        required
                        placeholder="Correo"
                        type="email"
                        name="email"
                        id="email"
                        aria-required="true"
                        aria-invalid="false"
                      />
                    </div>
                    <div className={"column one-second"}>
                      <input
                        onChange={e => setSubject(e.target.value)}
                        required
                        placeholder="Asunto"
                        type="text"
                        name="subject"
                        id="subject"
                        aria-invalid="false"
                      />
                    </div>
                    <div className={"column one-second"}>
                      <input
                        onChange={e => setPhone(e.target.value)}
                        placeholder="Teléfono"
                        type="tel"
                        name="phone"
                        id="phone"
                        aria-invalid="false"
                      />
                    </div>
                    <div className={"column one"}>
                        <textarea
                          onChange={e => setMessage(e.target.value)}
                          required
                          placeholder="Mensaje"
                          name="body"
                          id="body"
                          style={{ width: "100%" }}
                          rows={10}
                          aria-invalid="false"
                        ></textarea>
                    </div>
                    <div className={"column one"}>
                      <input type="submit" value="Enviar" id="submit"/>
                    </div>
                  </form>
                </div>
                <hr className={"no_line"} style={{ margin: "0 auto 20px" }}/>
              </div>
            </div>
            <div
              className={"column mcb-column one-third column_column"}
              style={{ height: "556px" }}
            >
              <div
                className={"column_attr"}
                style={{
                  backgroundImage: "url(/images/home_hr_sectionbg4.png",
                  backgroundRepeat: "repeat",
                  backgroundPosition: "center",
                  padding: "40px 8% 15px"
                }}
              >
                <h3>Contacto</h3>
                <br/>
                <h4>Ciudad de México</h4>
                <h5>
                  &nbsp;<i className={"fa fa-map-marker"}></i>&nbsp;&nbsp;
                  Montes Urales 424 | Lomas de Chapultepec, Miguel Hidalgo |
                  11000
                </h5>
                <h5>
                  <i className={"icon-phone"}> +52 (55) 6378-2805</i>
                </h5>

                <h4></h4>
                <h5>
                  <i className={""}></i>
                </h5>
                <hr
                  className={"no_line"}
                  style={{ margin: "30px auto 30px" }}
                />
                <h4>Nueva York</h4>
                <h5>
                  &nbsp;<i className={"fa fa-map-marker"}></i>&nbsp;&nbsp; 44
                  Wall Street | NY City | 10005
                </h5>
                <h5>
                  <i className={" "}>
                    {" "}
                    
                  </i>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)
}

export default Contacto
